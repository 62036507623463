import React from 'react';
import Height from "../components/vairableCss/height";

function Privacy(props) {
    return (
        <React.Fragment>

            <div className="main-content">

                <div className="container">
                    <Height height="120px"/>

                    <h3><b>PRIVACY STATEMENT</b></h3>
                    <p>All items are shipped via USPS Priority Mail from our CLOUD 9 SALON .
                        Most items arrive in 1-3 business days from shipment date.</p>
                    <p>----</p>


                    <p><b>SECTION 1 - WHAT DO WE DO WITH YOUR INFORMATION?</b></p>
                    <p>When you purchase something from our store, as part of the buying and selling process, we collect
                        the personal information you give us such as your name, address and email address.</p>
                    <p>When you browse our store, we also automatically receive your computer’s internet protocol (IP)
                        address in order to provide us with information that helps us learn about your browser and
                        operating system.</p>
                    <p>Email marketing (if applicable): With your permission, we may send you emails about our store,
                        new products and other updates.</p>

                    <p><b>Late or missing refunds</b></p>
                    <p>If you haven’t received a refund yet, first check your bank account again.</p>
                    <p>Then contact your credit card company, it may take some time before your refund is officially
                        posted.</p>
                    <p>Next contact your bank. There is often some processing time before a refund is posted.</p>
                    <p>If you’ve done all of this and you still have not received your refund yet, please contact us at
                        info@cl9salon.com.</p>

                    <p><b>SECTION 2 - CONSENT</b></p>
                    <p><b>--How do you get my consent?</b></p>
                    <p>When you provide us with personal information to complete a transaction, verify your credit card,
                        place an order, arrange for a delivery or return a purchase, we imply that you consent to our
                        collecting it and using it for that specific reason only.</p>
                    <p>If the item was a gift, you’ll receive a gift credit for the value of your return.</p>
                    <p>If we ask for your personal information for a secondary reason, like marketing, we will either
                        ask you directly for your expressed consent, or provide you with an opportunity to say no.</p>

                    <p><b>--How do I withdraw my consent?</b></p>
                    <p>If after you opt-in, you change your mind, you may withdraw your consent for us to contact you,
                        for the continued collection, use or disclosure of your information, at anytime, by contacting
                        us at info@cl9salon.com or mailing us at: Cloud 9 Salon 13380 Clarksville Pike, Suite F |
                        Highland, MD 20777, United States</p>

                    <p><b>SECTION 3 - DISCLOSURE</b></p>
                    <p>We may disclose your personal information if we are required by law to do so or if you violate
                        our Terms of Service.</p>


                    <p><b>SECTION 4 - CONSENT</b></p>
                    <p><b>--How do you get my consent?</b></p>
                    <p>When you provide us with personal information to complete a transaction, verify your credit card,
                        place an order, arrange for a delivery or return a purchase, we imply that you consent to our
                        collecting it and using it for that specific reason only.</p>
                    <p>If the item was a gift, you’ll receive a gift credit for the value of your return.</p>
                    <p>If we ask for your personal information for a secondary reason, like marketing, we will either
                        ask you directly for your expressed consent, or provide you with an opportunity to say no.</p>


                    <p><b>SECTION 4 - THIRD-PARTY SERVICES</b></p>
                    <p>In general, the third-party providers used by us will only collect, use and disclose your
                        information to the extent necessary to allow them to perform the services they provide to
                        us.</p>
                    <p>However, certain third-party service providers, such as payment gateways and other payment
                        transaction processors, have their own privacy policies in respect to the information we are
                        required to provide to them for your purchase-related transactions.</p>
                    <p>For these providers, we recommend that you read their privacy policies so you can understand the
                        manner in which your personal information will be handled by these providers.</p>
                    <p>In particular, remember that certain providers may be located in or have facilities that are
                        located in a different jurisdiction than either you or us. So if you elect to proceed with a
                        transaction that involves the services of a third-party service provider, then your information
                        may become subject to the laws of the jurisdiction(s) in which that service provider or its
                        facilities are located.</p>
                    <p>
                        As an example, if you are located in Canada and your transaction is processed by a payment
                        gateway located in the United States, then your personal information used in completing that
                        transaction may be subject to disclosure under United States legislation, including the Patriot
                        Act.</p>

                    <p>
                        Once you leave our store’s website or are redirected to a third-party website or application,
                        you are no longer governed by this Privacy Policy or our website’s Terms of Service.</p>

                    <p><b>--Links</b></p>
                    <p>When you click on links on our store, they may direct you away from our site. We are not
                        responsible for the privacy practices of other sites and encourage you to read their privacy
                        statements.</p>


                    <p><b>SECTION 5 - SECURITY</b></p>
                    <p>To protect your personal information, we take reasonable precautions and follow industry best
                        practices to make sure it is not inappropriately lost, misused, accessed, disclosed, altered or
                        destroyed.</p>
                    <p>If you provide us with your credit card information, the information is encrypted using secure
                        socket layer technology (SSL) and stored with a AES-256 encryption. Although no method of
                        transmission over the Internet or electronic storage is 100% secure, we follow all PCI-DSS
                        requirements and implement additional generally accepted industry standards.</p>
                    <p><b>--COOKIES</b></p>
                    <p>Here is a list of cookies that we use. We’ve listed them here so you can choose if you want to
                        opt-out of cookies or not.</p>
                    <p>_session_id, unique token, sessional, Allows Shopify to store information about your session
                        (referrer, landing page, etc).

                        _shopify_visit, no data held, Persistent for 30 minutes from the last visit, Used by our website
                        provider’s internal stats tracker to record the number of visits

                        _shopify_uniq, no data held, expires midnight (relative to the visitor) of the next day, Counts
                        the number of visits to a store by a single customer.

                        cart, unique token, persistent for 2 weeks, Stores information about the contents of your cart.

                        _secure_session_id, unique token, sessional

                        storefront_digest, unique token, indefinite If the shop has a password, this is used to
                        determine if the current visitor has access.</p>

                </div>
            </div></React.Fragment>
            );
            }

            export default Privacy;
