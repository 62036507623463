import React from 'react';
import Height from "../vairableCss/height";
import google from "../../assets/img/technology/google.png"
import apple from "../../assets/img/technology/apple.png"
function Technologies({ technology, nextTech, nextTechTitle }) {

    const getNextTech = () => {
        nextTech()
    }


    return (
        <>
            <div className="form-title">
                <h3>{technology.title}</h3>
                {technology.availableOn && technology.availableOn.length > 0 &&
                    <div className="app-store">
                        <p className="mr-md-5 mb-md-0">Available on: </p>
                        <div className="d-flex align-items-center icons-hodlder">
                            {technology.availableOn.map((store, key) => {
                                return <img key={key} width="120" src={store === 'google' ? google : apple} alt="play store" />
                            })}
                        </div>

                    </div>
                }

            </div>
            <Height height="40px" />

            <p>{technology.description}</p>
            <Height height="10px" />
            <div className="technologies">
                <div className="technology-list">
                    <ul className="single-list list-unstyled nav">
                        {technology.technologies.length > 0 && technology.technologies.map((tech, key) => {
                            return <li key={key}>{tech.name}</li>

                        })}
                    </ul>

                </div>
            </div>
            <Height height="80px" />
            <button onClick={getNextTech} className="btn-custom-sign-in">Next: {nextTechTitle} <i className="fa fa-angle-right"></i></button>
        </>
    );
}

export default Technologies;
