/* eslint-disable */
import React, {Component, useEffect} from 'react'
import HeroSlider from '../components/BannerSlider/BannerSlider';
import homeSliderData from '../components/Data/homeSliderData'
import {connect} from 'react-redux';
import {Helmet} from "react-helmet";
import sliderData from "../components/BannerSlider/sliderData";

class Home extends Component {
    constructor(props) {
        super(props);


    }

    componentDidMount() {

        window.collapseMenu()

    }

    //set index for active header

    render() {

        return (
            <div
                id="heroSlider"
            >
                <HeroSlider sliderData={sliderData} home={true}/>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Home - Silverbird - The salon of the future</title>
                </Helmet>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {activeIndex} = state.headerReducer
    return {activeIndex}
}

export default connect(mapStateToProps)(Home)
