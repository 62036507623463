
export const salonContent = [
    {
        id: 1,
        title: 'Cloud 9 Salon',
        img: 'https://silverbird.s3.amazonaws.com/123456789/images/meta/team/Jonathan-072019.jpg',
        name: [{firstName: 'Jonathan', lastName: 'Atkinson', nick: 'Jonathan'}],
        description: 'We are passionate about hair; we consciously stay on top of the latest trends and techniques in the industry to bring out the best in your hair. We have some of the best colorists in the world. The products we use are the best and our work speaks for itself. We can boldly say we are the best in the area. Go ahead and make an appointment today with our award winning stylists.',
        address: {
            line1: '13380 Clarksville Pike,',
            line2: 'Suite F | Highland, MD 20777',
            number: '(301)854-9511'
        },
        url: 'www.cl9salon.com',
        short: 'Cloud 9 Salon'
    },
    {
        id: 2,
        title: 'Salon H2O',
        img: 'https://silverbird.s3.amazonaws.com/234567890/images/meta/team/Alexis-042519.jpg',
        name: [{firstName: 'Alexis', lastName: 'Carrera', nick: 'Alexis'}],
        description: 'We are a family-oriented Aveda concept salon and we are proud to say that we do our part in taking care of the environment by using Aveda. We ensure that our clients are always given priority and that is why we are known to have the best customer service in the Olney area. We are a full service salon that offers comprehensive services that include your hair, nails, lashes, skin and body. You won’t be disappointed, come give us a try. ',
        address: {
            line1: '16826 Georgia Avenue,',
            line2: 'Olney, MD 20832',
            number: '(301)774-7999'
        },
        url: 'www.mysalonh2o.com',
        short: 'Salon H2O'
    },
    {
        id: 3,
        title: 'Eterna Lash & Brow',
        img: 'https://silverbird.s3.amazonaws.com/default/user-no-image.png',
        name: [{firstName: 'Kadrya', lastName: 'Sindi', nick: 'Nora'}],
        description: 'At Eterna Lash & brow, we bring the best out of your lashes and brows. We enhance natural lashes by either a lash lift, tint, or extension. We use faux mink lash extensions and can achieve different types of look such as classic, hybrid, volume, and mega volume all based on what our client needs. For brows, we enhance by tinting, microblading, microshading or a combination. Come visit our salon to discover the beauty of your lashes and brows.',
        address: {
            line1: '12274D Rockville Pike,',
            line2: 'Rockville, MD 20852',
            number: '(240)242-4321'
        },
        url: 'www.eternalashbrow.com',
        short: 'Eterna Lash'
    },
    {
        id: 4,
        title: 'Eterna Day Spa',
        img: 'https://silverbird.s3.amazonaws.com/472822496/images/meta/team/Lucie-051019.jpg',
        name: [{firstName: 'Tuyen', lastName: 'Luu', nick: 'Lucie'}],
        description: 'At Eterna day spa, we make your nails look good and also keep them healthy. Some of our other services are lash, waxing, massage and skin care but our lash extensions is the second most popular service we offer. Our nail technicians specialize in the best designs in the industry and ensure that your nails are not compromised whether you decide to keep it natural or use extensions. Our specialty pedicures such as chocolate, therapy, mango and green tea are to die for. Make some time to come in for a pedicure to get the relaxation you deserve. ',
        address: {
            line1: '11160 Veirs Mill Rd, Spc#162',
            line2: 'Wheaton, MD 20902',
            number: '(301)949-4646'
        },
        url: 'www.eternadayspa.com',
        short: 'Eterna Day Spa'
    }
]
