import React, { Component } from 'react';
import { Link, withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux'
import classname from 'classnames'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
} from 'reactstrap';


import Logo from '../../assets/img/sp-logo.png';
import LogoWhite from '../../assets/img/logo-white.png';
import bar from '../../assets/img/Hamburger-style.png'


class Header extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      scrolling: false,
      transparene: false,
    }

    this.toggle = this.toggle.bind(this)
  }

  componentDidMount() {
    if (this.props.location.pathname !== '/') {
      this.setState({
        transparene: true
      })

    }


    // OnScolll
    const onScroll = e => {
      this.setState({
        scrolling: e.target.documentElement.scrollTop > 100
      })
    }

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll)


  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.location.pathname !== '/') {
      this.setState({
        transparent: true
      })
    } else {
      this.setState({
        transparent: false
      })
    }
  }

  toggle() {
    window.uncollapseMenu()
  }

  render() {
    const { activeIndex } = this.props



    return (
      <Navbar id={activeIndex === 0 ? 'active' : 'inactive'} style={
        //eslint-disable-next-line
        this.props.location.pathname != '/' ? { backgroundColor: '#fff' } : { backgroundColor: 'transparent' }} className={classname("navbar-transparent", { "fixed-top": this.state.scrolling, 'navbar-white': this.state.transparent })} expand="md">
        <Link className="logo-mobile" to="/">
          {activeIndex === 0 ? <img src={LogoWhite} alt="Logo" /> : <img src={LogoWhite} alt="Logo" />}
        </Link>
        <NavbarToggler onClick={this.toggle} />
        <Collapse navbar>

          <Nav navbar className="navbar-nav-left">
            {/*<NavItem>*/}
            {/*  <NavLink activeClassName="active" activeStyle={{borderBottom: '1px solid #fff'}} className="nav-link" to="/salons">Salons</NavLink>*/}
            {/*</NavItem>*/}

            <NavItem>
              <NavLink className="nav-link" to="/about">About</NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="nav-link" to="/salons">Salons</NavLink>
            </NavItem>

            <NavItem>
              <NavLink className="nav-link" to="/contact">Contact</NavLink>
            </NavItem>
          </Nav>


          <Link className="logo-desktop" to="/">
            {activeIndex === 0 ? <img src={LogoWhite} alt="Logo" /> : <img src={LogoWhite} alt="Logo" />}


          </Link>

          <Nav navbar className="navbar-nav-right">
            {
              //eslint-disable-next-line
              this.props.location.pathname == '/' ?
                <NavItem>
                  <a className="nav-link" target="_blank" href="http://admin.silverbird.io" rel="noopener noreferrer"></a>
                </NavItem>
                : <div className="bar"> <img src={bar} alt="bar" /> <div className="rotate-menu"><p>{this.props.location.pathname.split('/')[1].toUpperCase()}</p></div> </div>}


          </Nav>

        </Collapse>
      </Navbar>
    )
  }

}

function mapStateToProps(state) {
  const { activeIndex } = state.headerReducer;
  return { activeIndex }
}



export default connect(mapStateToProps)(withRouter(Header));
