import React, {useEffect, useState} from 'react';

import Background from "../components/full-height-background/background";

import Technologies from "../components/technology/Technologies";
import { technologyContent } from "../components/Data/technologyContent"
import {connect} from 'react-redux'
import Height from "../components/vairableCss/height";
import {Helmet} from "react-helmet";

const Technology = (props) => {
  let [index, setIndex] = useState(0)
  let [loading, setLoading] = useState(false)

  const nextTech = () => {
    setLoading(true)
    if (index === technologyContent.length - 1) {
      setTimeout(() => {
        setIndex(0)
        setLoading(false)
      }, 100)
    } else {
      setTimeout(() => {
        setIndex(index + 1)
        setLoading(false)
      }, 100)
    }
  }
  useEffect(()=>{
    window.collapseMenu()
  })
  return (
    <div
      className="main-content technology-page">
      <div className="container-fluid">
        <div className="height-50"></div>
        <div className="content d-flex flex-column flex-lg-row position-relative">
          {loading === true &&
            <div className="loader">
              <i className="fa fa-spinner fa-pulse"></i>
            </div>
          }
          <Background image={technologyContent[index].img} />
          <div className="content-form">
            <Height height="40px"/>
            <Technologies nextTech={nextTech} technology={technologyContent[index]} nextTechTitle={
              // eslint-disable-next-line
              index == technologyContent.length - 1 ? technologyContent[0].title : technologyContent[index + 1].title} />
          </div>
        </div>
      </div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Technology - Silverbird - The salon of the future</title>
      </Helmet>
    </div>
  )
}

export default connect()(Technology);
