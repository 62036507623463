

const salonSliderData = [

    [
        {
            id: 1,
            img: 'https://silverbird.s3.amazonaws.com/123456789/images/meta/lctn/lctn-020720-3.jpg'
        },
        {
            id: 2,
            img: 'https://silverbird.s3.amazonaws.com/123456789/images/meta/lctn/lctn-020720-1.jpg'
        },
        {
            id: 3,
            img: 'https://silverbird.s3.amazonaws.com/123456789/images/meta/lctn/lctn-020720-2.jpg'
        },
        {
            id: 4,
            img: 'https://silverbird.s3.amazonaws.com/123456789/images/meta/lctn/lctn-020720-4.jpg'
        },
        {
            id: 5,
            img: 'https://silverbird.s3.amazonaws.com/123456789/images/meta/lctn/lctn-020720-5.jpg'
        }],

    [
        {
            id: 1,
            img: 'https://silverbird.s3.amazonaws.com/234567890/images/meta/lctn/D19206-1531_2.jpg'
        },
        {
            id: 2,
            img: 'https://silverbird.s3.amazonaws.com/234567890/images/meta/lctn/D19206-1545.jpg'
        },
        {
            id: 3,
            img: 'https://silverbird.s3.amazonaws.com/234567890/images/meta/lctn/D19206-1591.jpg'
        },
        {
            id: 4,
            img: 'https://silverbird.s3.amazonaws.com/234567890/images/meta/lctn/D19206-1605.jpg'
        },
        {
            id: 5,
            img: 'https://silverbird.s3.amazonaws.com/234567890/images/meta/lctn/D19206-1488.jpg'
        }
    ],

    [
        {
            id: 1,
            img: 'https://silverbird.s3.amazonaws.com/345678901/images/meta/lctn/D19192-7798.jpg'
        },
        {
            id: 2,
            img: 'https://silverbird.s3.amazonaws.com/345678901/images/meta/lctn/D19192-7816.jpg'
        },
        {
            id: 3,
            img: 'https://silverbird.s3.amazonaws.com/345678901/images/meta/lctn/D19192-7927.jpg'
        },
        {
            id: 4,
            img: 'https://silverbird.s3.amazonaws.com/345678901/images/meta/lctn/D19192-7866.jpg'
        }
    ],
    [
        {
            id: 1,
            img: 'https://silverbird.s3.amazonaws.com/472822496/images/meta/lctn/6P5A9498.jpg'
        },
        {
            id: 2,
            img: 'https://silverbird.s3.amazonaws.com/472822496/images/meta/lctn/6P5A0542.jpg'
        },
        {
            id: 3,
            img: 'https://silverbird.s3.amazonaws.com/472822496/images/meta/lctn/6P5A0563.jpg'
        },
        {
            id: 4,
            img: 'https://silverbird.s3.amazonaws.com/472822496/images/meta/lctn/6P5A0548.jpg'
        }],
];

export default salonSliderData;
