import React, { useEffect, useState } from 'react';
import SwiperCore, { Autoplay, Navigation, EffectFade } from 'swiper';
import HeroSlider from "../components/BannerSlider/BannerSlider";
import salonSliderData from '../components/Data/salonSliderData'
import { salonContent } from "../components/Data/salonContent";
import Salons from "../components/Salons/salons";
import {connect} from 'react-redux'
import Height from "../components/vairableCss/height";
import {Helmet} from "react-helmet";

SwiperCore.use([Autoplay, Navigation, EffectFade]);
const SalonPage = props => {
    let [index, setIndex] = useState(0)
    let [loading, setLoading] = useState(false)

    const nextSalon = () => {
        setLoading(true)
        //eslint-disable-next-line
        if (index == salonContent.length - 1) {
            setTimeout(() => {
                setIndex(0)
                setLoading(0)
            }, 200)
        } else {
            setTimeout(() => {
                setIndex(index + 1)
                setLoading(false)
            }, 200)
        }
    }
    useEffect(()=>{
        window.collapseMenu()
    })

    return (
        <div
            className="main-content salon-page">
            <div className="container-fluid">
                <div className="height-50"></div>
                <div className="content d-flex flex-column flex-lg-row position-relative">
                    {loading === true &&
                        <div className="loader">
                            <i className="fa fa-spinner fa-pulse"></i>
                        </div>
                    }
                    <div className="content-thumb">

                        <HeroSlider sliderData={salonSliderData[index]} />
                    </div>
                    <div className="content-form">
                        <Height height="40px"/>
                        <Salons nextTechTitle={
                            // eslint-disable-next-line
                            index == salonContent.length - 1  ? salonContent[0].title : salonContent[index + 1].short} nextSalon={nextSalon} salon={salonContent[index]} />
                    </div>
                </div>
            </div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Salons - Silverbird - The salon of the future</title>
            </Helmet>
        </div>
    )
}

export default connect()(SalonPage);
