import React, { useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/effect-fade/effect-fade.scss';
import Team from './pages/Team'

// Custom component
import Layout from './layouts/Layouts';
import Home from './pages/Home';
import Salon from './pages/SalonPage';
import Technology from './pages/Technology';
import About from './pages/About';
import Contact from './pages/Contact';
import { connect } from 'react-redux'
import { userServices } from "./_services";
import Privacy from "./pages/Privacy";



const App = (props) => {
  const location = useLocation();
  useEffect(() => {
    getAllSalonStaffs()
    window.changeByLocation(location.pathname)
  });
  const getAllSalonStaffs = () => {
    userServices.getAllSalonStaffs().then(staffs => {
      console.log('staff', staffs)
      var size = 6;
      var detailsCollections = [];
      for (var i = 0; i < staffs.length; i += size) {
        detailsCollections.push(staffs.slice(i, i + size));
      }
      localStorage.removeItem('staffs')
      localStorage.setItem('staffs', JSON.stringify(detailsCollections))
    })
  }

  return (

    <Layout>

      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/salons" component={Salon} />
        <Route path="/technology" component={Technology} />
        <Route path="/about" component={About} />
        <Route path="/contact" component={Contact} />
        <Route path="/team" component={Team} />
        <Route path="/privacy" component={Privacy} />
      </Switch>
    </Layout>

  );
}

export default connect()(App);
