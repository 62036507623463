import React from 'react'


import Header from '../components/Header/Header';
import Footer from "../components/Footer/footer";
import CopyRight from "../components/Copyright/copyRight";

const Layouts = (props) => {
    console.log(props.children)
  return (
    <>
      <Header />
      {props.children}
      <Footer/>
      <CopyRight/>
    </>
  )
}

export default Layouts;
