import React from 'react'
import SwiperCore, {Pagination, Autoplay} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import {connect} from 'react-redux'

// Import Swiper styles
//import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import bannerText from '../../assets/img/banner/banner_text.png'

// install Virtual module
SwiperCore.use([Pagination, Autoplay]);

class HeroSlider extends React.Component {

    onSlideChange = (swiper) => {
        window.changeNav(swiper.realIndex)
        // eslint-disable-next-line
        // if(this.props.home == true) {
        //     dispatch(headerAction.activeHeader(swiper.realIndex))
        // }else{
        //     dispatch(headerAction.activeHeader(swiper.realIndex + 1))
        // }

    }

    render() {
        const {sliderData} = this.props
        console.log('sliderData',sliderData)
        return (
            <Swiper
                onSlideChange={(swiper) => this.onSlideChange(swiper)}
                loop='true'
                tag='section'
                wrapperTag="ul"
                slidesPerView={1}
                pagination={{
                    clickable: true,
                    renderBullet: (index, classname) => {
                        //return '<span style="color: white" class="' + classname + '">' + '0' + (index + 1) + '</span>';
                        return `<span style="color: white" class="${classname}">0${index + 1}</span>`

                    }
                }}
            >
                <SwiperSlide tag="li">
                    <div className={`slider-bg-holder ${sliderData[0].cls ? sliderData[0].cls : 'not-home'}`}
                         style={{backgroundImage: `url(${sliderData[0].img})`}}>

                        <div className="banner-content">
                            <div className="banner-text-image">
                                <img src={bannerText} alt="Banner text"/>
                            </div>
                        </div>

                    </div>
                </SwiperSlide>
                {
                    sliderData.slice(1, 7).sort(() => Math.random() - Math.random()).slice(0, 3).map((e, key) => {

                        return (<SwiperSlide tag="li" key={key}>
                            <div className={`slider-bg-holder ${e.cls ? e.cls : 'not-home'}`}
                                 style={{backgroundImage: `url(${e.img})`}}>
                                {e.title &&
                                <div className="banner-content">
                                    <span className="title">{e.title} {e.longTitle &&
                                    <br/>} {e.longTitle && e.longTitle}</span>
                                    {e.salonLinks.sort(() => Math.random() - Math.random()).slice(0, 1).map((url, key) => {
                                        return <p key={key} className="sub-title"><a rel="noopener noreferrer"  target="_blank"
                                                                                     href={url}>{e.button}</a></p>
                                    })}

                                </div>
                                }
                            </div>
                        </SwiperSlide>)

                    })
                }
            </Swiper>

        );
    }


};

function mapStateToProps(state) {
    const {activeIndex} = state.headerReducer;
    return {activeIndex}
}

export default connect(mapStateToProps)(HeroSlider);
