import {headerConstants} from "../_constants";



export const headerReducer=(state={}, action)=>{

    switch (action.type) {
        case headerConstants.ACTIVE_HEADER_SUCCESS:
            return {
                activeIndex: action.activeIndex
            }

        default:
            return state
    }
}
