import React from 'react';
import Height from "../vairableCss/height";

function salons({salon,nextSalon,nextTechTitle}) {

    const getNextSalon = () =>{
        nextSalon()
    }

    return (
        <>
            <div
                className="form-title">
                <h3>{salon.title}</h3>

                <div className="d-flex align-items-center">
                    <img className="rounded-circle" width="30" src={salon.img} alt={salon.title}/>
                    &nbsp; &nbsp; <p style={{marginBottom: 0}}>{salon.name[0].nick}</p>
                </div>

            </div>

            <p>{salon.description}</p>

            <Height height="70px"/>
            <div className="address">
                    <p>{salon.address.line1}</p>
                    <p>{salon.address.line2}</p>
                    <p>{salon.address.number}</p>
                    <p><a target="_blank" href={'https://'+salon.url} rel="noopener noreferrer" >{salon.url}</a></p>

            </div>
            <Height height="60px"/>
            <div className="d-flex justify-content-between navigate">

                <button  onClick={getNextSalon}  className="btn-custom-sign-in">Next: {nextTechTitle}  <i className="fa fa-angle-right"></i></button>
            </div>

        </>
    );
}

export default salons;
