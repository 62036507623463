import staffApp from '../../assets/img/technology/staff.jpg'
import customer from '../../assets/img/technology/customer_app.jpg'
import clover from '../../assets/img/technology/clover.jpg'
import mirror from '../../assets/img/technology/mirror.jpg'
import admin from '../../assets/img/technology/admin.jpg'
import salons from '../../assets/img/technology/salons.jpg'

export const technologyContent = [

    {
        id: 1,
        title: 'Admin App',
        img: admin,
        description: 'We designed the Silverbird Admin App to make managing our salons as easy as possible. To do so, our Admin App features standard appointment booking, inventory management, reporting, and combines this with client loyalty features, smart incentives, marketing, membership billing, integrations, and several other useful features. Our telephone integration further decentralizes the front desk by allowing for remote customer support when the salon is not open. The Admin App serves as the configuration tool for all other Silverbird applications, thereby allowing for a streamlined user experience across apps and devices.',
        availableOn: [],
        technologies: [
            {name: 'React JS'},
            {name: 'Spring'},
            {name: 'AWS'},
            {name: 'Twilio'},
        ]
    },
    {
        id: 2,
        title: 'Customer App',
        img: customer,
        description: 'With the Silverbird Customer App, our salon clients gain efficiencies and control of their appointments, communications and payments. Essentially, we put them in the driver seat and, in turn, our teams spend more time interacting with clients who are in the salon while knowing the ones who aren’t are perfectly satisfied. Clients are able to book/re-book past appointments and buy products they’ve purchased in the past in a few simple clicks. Our messaging feature keeps our clients in touch with the salon at any time.',
        availableOn: ['apple','google'],
        technologies: [
            {name: 'React'},
            {name: 'Spring'},
            {name: 'AWS'},
        ]

    },

    {
        id: 3,
        title: 'Clover App',
        img: clover,
        description: 'The Silverbird Clover App makes checkout and payment processing a painless experience, by extending our platform to run on the Clover device and allowing our Front Desk team to complete these tasks quickly and efficiently. Generated receipts can be customized with options to print or send via email.',
        availableOn: [],
        technologies: [
            {name: 'Android'},
            {name: 'Spring'},
            {name: 'AWS'}
        ]
    },
    {
        id: 4,
        title: 'Staff App',
        img: staffApp,
        description: 'Our Silverbird Staff App empowers our stylists to manage their book and monitor performance with real-time stats. It gives them real-time chat access with their clients, and an easy way to recommend products alongside services when booking appointments. Our support staff is also provided with an easy to use time clock that allows them to track their schedule and attendance, and can track their timesheet as well.',
        availableOn: ['apple','google'],
        technologies: [
            {name: 'React'},
            {name: 'Spring'},
            {name: 'AWS'},
        ]

    },
    {
        id: 5,
        title: 'Consultation App',
        img: mirror,
        description: 'The Silverbird Consultation App runs on our smart mirror, to allow our stylists and clients visualize the consultation process together. Leveraging the power of Artificial Intelligence, our smart mirror allows clients to experience hair color try-on and visualize color suitability. Our Facial analysis and celebrity match features show them famous people who share similar face shape and bone structure using 128-point facial analysis, and lets them compare different hairstyles and colors on someone similar. Past Looks keeps records of client transformation, for future reference. It also offers customized product recommendation for each client.',
        availableOn: [],
        technologies: [
            {name: 'Android'},
            {name: 'Spring'},
            {name: 'AWS'},
            {name: 'TensorFlow'},
            {name: 'Azure'}
        ]
    },

    {
        id: 6,
        title: 'Salon Websites',
        img: salons,
        description: 'Our salon websites were designed with a simple focus - simplicity. The websites allow users learn more about the salons and services offered, and it also provides a close integration with our Silverbird platform, giving users easy access to appointment booking and products purchase. The layout of each salon website allows for promotional offers to be displayed via web banners and is powered by a 24/7 online chat tool, for continuous customer support. ',
        availableOn: [],
        technologies: [
            {name: 'React JS'},
            {name: 'Spring'},
            {name: 'AWS'}
        ]
    },



]
