/* eslint-disable */
import React, { useEffect, useState } from 'react';

import Background from "../components/full-height-background/background";
import image from "../assets/img/about/seyi7.jpg";
import image1 from "../assets/img/about/seyi1.jpg";
import Height from "../components/vairableCss/height";
import { Link } from "react-router-dom";
import { connect } from 'react-redux'
import classnames from 'classnames';
import {
  Carousel,
  CarouselItem
} from 'reactstrap';
import { Helmet } from "react-helmet";
const aboutImages = [image, image1]
const About = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === aboutImages.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? aboutImages.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  useEffect(() => {
    window.collapseMenu()
  })

  const slides = aboutImages.map((item, key) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={key}
      >
        <div className={key == 0 ? 'content-thumb initial' : 'content-thumb'} style={{ backgroundImage: 'url(' + item + ')' }}></div>

      </CarouselItem>
    );
  });

  return (
    <div
      className="main-content about about-special">
      <div className="container-fluid">
        <div className="height-50"></div>
        <div className="content d-flex flex-column flex-lg-row  position-relative">
          <div className="carousel-about">
            <Carousel
              activeIndex={activeIndex}
              next={next}
              previous={previous}
              interval={false}
            >
              <ol className="carousel-indicators">
                {aboutImages.map((item, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() => goToIndex(index)}
                      className={classnames({ active: activeIndex === index })}
                      style={{ backgroundImage: `url(${item.src})` }}
                    >{'0' + (index + 1)}</li>
                  );
                })}
              </ol>
              {slides}

            </Carousel>
          </div>
          <div className="content-form">
            <Height height="40px" />
            <div className="form-title">
              <h3>Seyi Badmus</h3>
              <p>Owner/CEO</p>
            </div>
            <span>MOST PEOPLE SAY REAL BEAUTY IS ON THE INSIDE</span>

            <p>I somewhat agree that even though inner beauty defines how attractive you are as a human being, your outward appearance also matters. Your outward appearance allows others to be drawn to you to discover your inner beauty. I ventured into the beauty industry by chance a few years ago when I left my career as a nurse. </p>

            <p>My passion as a nurse in serving others also transcends into the beauty industry which is why I am very passionate about customer service in the salon with technology as a component. I believe that technology is the future of the salon industry and that future is Silverbird where the salon and technology work together hand in hand. </p>
            <Height height="40px" />
            <div className="d-flex justify-content-between align-items-center">
              <Link to="/team" className="btn-custom-sign-in">The team</Link>
              <a href="mailto:info@silverbird.com">Inquiries: info@silverbird.io</a>
            </div>
          </div>
        </div>
      </div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About - Silverbird - The salon of the future</title>
      </Helmet>
    </div>
  )
}

export default connect()(About);
