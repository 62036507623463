export const userServices = {
    getAllSalonStaffs
}

const apiPath = 'https://api-v2.silverbird.io/new-technicians?page=0&pageCount=100'

function getAllSalonStaffs(){
    const requestOptions = {
        method: 'GET',
        headers: new Headers({
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        })
    }
    return fetch(apiPath,requestOptions).then(handleResponse)
}

function handleResponse(response) {
    if(!response.ok){
        return Promise.reject(response);
    }else {
        return response.json()
    }
}
