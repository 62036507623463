import React from 'react';


function SingleTeam({tech,keys}) {
    return (
        <div className="single-salon-team d-flex align-items-center" key={keys}>
            <div className="thumb">
                <img className="rounded-circle" width="70"
                     src={tech.img}
                     alt=""/>
            </div>
            <div className="introduction">
                <h6 style={{ marginBottom: '0px' }}>{tech.name}</h6>
                <p style={{marginBottom: '0px'}} className="text-info">{tech.title}</p>
                <h6 style={{marginBottom: '0px'}}>{tech.technologies}</h6>
            </div>
        </div>
    );
}


export default SingleTeam;
