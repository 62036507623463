import React from 'react';
import {useLocation } from 'react-router-dom';
function CopyRight(props) {
    let location = useLocation()

    return (
        <div style={location.pathname !== '/' ? {display: 'block'} : {display: 'none'}}  className="copy-right-section">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>&copy;  Copyright {new Date().getFullYear()}, Silverbird Inc</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CopyRight;
