import React from 'react';
import assLogo from '../../assets/img/logo.png'
import { withRouter } from 'react-router-dom';
class Footer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            transparent: false
        }
    }


    render() {


        return (
            <div id={
                //eslint-disable-next-line
                this.props.location.pathname == '/privacy' ? 'scroller' : ''} style={this.props.location.pathname !== '/' ? {display: 'block'} : {display: 'none'}} className="footer-logo">
                <img src={assLogo} alt="Footer Logo"/>
            </div>
        );
    }
}

export default withRouter(Footer);
