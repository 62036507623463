import silverbird from '../../assets/img/banner/silverbird.jpg'
import drop_dead_gorgeous from '../../assets/img/banner/drop_dead_gorgeous.jpg'
import beautiful_perfect from '../../assets/img/banner/beautiful_perfect.jpg'
import healthy_fabulous from '../../assets/img/banner/healthy_fabulous.jpg'
import look_your_best from '../../assets/img/banner/look_your_best.jpg'
import more_beautiful_looking from '../../assets/img/banner/more_beautiful_looking.jpg'
import relax_rejuvenate from '../../assets/img/banner/relax_rejuvenate.jpg'

const sliderData = [
  {
    id: 1,
    img: silverbird,
    title: 'longer, thicker,',
    longTitle: ' more beautiful-looking',
    button: 'book now',
    cls: 'silverbird',
    salonLinks:['https://www.mysalonh2o.com/','https://www.mysalonh2o.com/','https://www.mysalonh2o.com/','https://www.mysalonh2o.com/']
  },
  {
    id: 2,
    img: drop_dead_gorgeous,
    title: 'drop-dead gorgeous!',
    button: 'book now',
    cls: 'gorgeous',
    salonLinks:['https://www.mysalonh2o.com/','https://www.mysalonh2o.com/','https://www.mysalonh2o.com/','https://www.mysalonh2o.com/']
  },
  {
    id: 3,
    img: beautiful_perfect,
    title: 'beautiful & perfect',
    button: 'book now',
    cls: 'perfect',
    salonLinks:['https://www.mysalonh2o.com/','https://www.mysalonh2o.com/','https://www.mysalonh2o.com/','https://www.mysalonh2o.com/']
  },
  {
    id: 4,
    img: healthy_fabulous,
    title: 'healthy & fabulous',
    button: 'book now',
    cls: 'fabulous',
    salonLinks:['https://www.mysalonh2o.com/','https://www.mysalonh2o.com/','https://www.mysalonh2o.com/','https://www.mysalonh2o.com/']
  },
  {
    id: 5,
    img: more_beautiful_looking,
    title: 'longer, thicker,',
    longTitle: ' more beautiful-looking',
    button: 'book now',
    cls: 'looking',
    salonLinks:['https://www.mysalonh2o.com/','https://www.mysalonh2o.com/','https://www.mysalonh2o.com/','https://www.mysalonh2o.com/']
  },
  {
    id: 6,
    img: look_your_best,
    title: 'look your best',
    button: 'book now',
    cls: 'best',
    salonLinks:['https://www.mysalonh2o.com/','https://www.mysalonh2o.com/','https://www.mysalonh2o.com/','https://www.mysalonh2o.com/']
  },
  {
    id: 7,
    img: relax_rejuvenate,
    title: 'relax & rejuvenate',
    button: 'book now',
    cls: 'rejuvenate',
    salonLinks:['https://www.mysalonh2o.com/','https://www.mysalonh2o.com/','https://www.mysalonh2o.com/','https://www.mysalonh2o.com/']
  }
];

export default sliderData;
